import {downloadCSV} from "react-admin";
import jsonExport from 'jsonexport/dist';

export const Exporter = payments => {
    const paymentsForExport = payments.map(payment => {
        const {formattedUpdatedAt, paid_at, status, status_code, status_message, amount, translatedStatus, updated_at, ...paymentForExport} = payment;
        paymentForExport.amount = new Intl.NumberFormat('it-IT', {
            currency: 'EUR',
            style: 'currency'
        }).format(amount / 100);
        paymentForExport.status = translatedStatus;
        paymentForExport.updated_at = `${updated_at}`; //(${formattedUpdatedAt}) -- anche export?
        return paymentForExport;
    });

    jsonExport(paymentsForExport, {
        headers: ['id','transaction_id','code','amount','description','longDescription','notes','status','created_by', 'created_at', 'updated_at'],
        rename: ['ID', 'ID Transazione', 'Codice', 'Costo', 'Descrizione', 'Dettagli', 'Note', 'Stato', 'Creato da', 'Data creazione', 'Ultimo aggiornamento']
    }, (err, csv) => {
        const date = (new Date());
        downloadCSV(csv, `payments_${date.getFullYear()}-${date.getMonth()}-${date.getDay()}_${date.getTime()}`);
    });
}

/*

export const Exporter = (records, fetchRelatedRecords, provider) => {
    provider.getExportData('payments').then( response => {
        const paymentsForExport = response.data.map(payment => {
            const {formattedUpdatedAt, paid_at, status, status_code, status_message, amount, translatedStatus, updated_at, ...paymentForExport} = payment;
            paymentForExport.amount = new Intl.NumberFormat('it-IT', {
                currency: 'EUR',
                style: 'currency'
            }).format(amount / 100);
            paymentForExport.status = translatedStatus;
            paymentForExport.updated_at = `${updated_at}`; //(${formattedUpdatedAt}) -- anche export?
            return paymentForExport;
        });

        jsonExport(paymentsForExport, {
            headers: ['id','transaction_id','code','amount','description','longDescription','notes','status','created_by', 'created_at', 'updated_at'],
            rename: ['ID', 'ID Transazione', 'Codice', 'Costo', 'Descrizione', 'Dettagli', 'Note', 'Stato', 'Creato da', 'Data creazione', 'Ultimo aggiornamento']
        }, (err, csv) => {
            const date = (new Date());
            downloadCSV(csv, `payments_${date.getFullYear()}-${date.getMonth()}-${date.getDay()}_${date.getTime()}`);
        });
    })


}
 */
