import * as React from 'react';
import {
    Sidebar,
    MenuItemLink,
    useGetIdentity,
    useLogout,
    useResourceDefinitions,
    useCheckAuth,
    useAuthState
} from 'react-admin';

import {Collapse, Divider, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import {useTranslate} from "ra-core";


export const MenuCustom = (props) => {
    const { identity } = useGetIdentity();
    const {authenticated} = useAuthState();
    const [open, setOpen] = React.useState(false);
    const resourcesDefinitions = useResourceDefinitions();
    const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);
    const translate = useTranslate();
    const logout = useLogout();
    const handleClick = () => {
        setOpen(!open);
    };


    return (
        <Sidebar {...props} className={"sidebar-container"}>
                <MenuItemLink className={"menu-list"}  to="#" primaryText={process.env.REACT_APP_NAME} leftIcon={<HomeIcon/>}/>
            {authenticated !== undefined && <Divider className={"menu-separator"}/>}
            {authenticated !== undefined && <List
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItem button onClick={handleClick} className={"account-section"}>
                    <ListItemIcon className={'user-circle-icon'}>
                        <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={identity ? identity.fullName?.length > 15 ? `${identity.fullName?.substring(0, 15)}...` : identity?.fullName : ''}/>
                    {(open && authenticated !== undefined) ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                {authenticated !== undefined && <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button onClick={logout}>
                            <ListItemIcon className={'user-circle-icon'}>
                                <LogoutIcon/>
                            </ListItemIcon>
                            {translate('ra.auth.logout')}
                        </ListItem>
                    </List>
                </Collapse>}
            </List>}
            <Divider className={"menu-separator"}/>
            {resources.map(resource => (
                    <MenuItemLink key={resource.name} className={"menu-list"} to={`/${resource.name}`} primaryText={translate(`resources.${resource.name}.name`, {smart_count: 2})} leftIcon={resource.icon} />
                )
            )}

        </Sidebar>
    )
};
