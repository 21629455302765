import * as React from 'react';
import { Layout } from 'react-admin';
import {CustomAppBar} from "../Appbar";
import {MenuCustom} from "../Menu";

export const AppLayout = (props) => <Layout
    {...props}
    appBar={CustomAppBar}
    sidebar={MenuCustom}
/>;

