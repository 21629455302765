import {defaultTheme} from "react-admin";


const text = "#333";
const lightBlue = "rgb(26, 115, 232)";
const white = "#fff";

export const theme = {
    ...defaultTheme,
    components: {
        ...defaultTheme.components,
        MuiTypography: {
            styleOverrides: {
                h6: {
                    color: white
                }
            }
        },
        RaUserMenu: {
            styleOverrides: {
                userButton: {
                    22: {
                        color: "#f3f344"
                    }
                },
            }
        },
        MuiDrawer:{
            styleOverrides: {
                root:{
                    "&.sidebar-container": {
                        borderRadius: "10px",
                        height: "unset !important",
                    },
                },
            },
        },

        MuiListItemIcon: {
            styleOverrides: {

                root: {
                minWidth:'40px !important',
            }
            },
        },


        MuiPaper: {
            styleOverrides: {
                root: {
                    "& .RaSidebar-fixed": {
                    background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                    width: "unset",
                    position: "relative",
                    color: "#fff",
                    borderRadius: "10px",
                    height: "calc(100% - 80px)",
                    marginTop: "18px",
                    '& a': {
                        color: "#fff",
                    },
                    '& svg': {
                        color: "#fff",
                    },
                    "& .menu-separator": {
                        flexShrink: "0",
                        borderTop: "0px solid rgba(0, 0, 0, 0.08)",
                        borderLeft: "0px solid rgba(0, 0, 0, 0.08)",
                        borderRight: "0px solid rgba(0, 0, 0, 0.08)",
                        borderBottom: "none",
                        height: "0.0625rem",
                        margin: "1rem 0px",
                        opacity: "0.25",
                        backgroundColor: "transparent",
                        backgroundImage: "linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgba(255, 255, 255, 0)) !important",
                    },
                    "& .menu-list": {
                        margin: "20px 0",
                    },
                    "& .MuiListItem-root": {
                        "& .user-circle-icon": {
                            minWidth: "0px !important",
                            marginRight: "15px",
                        }
                    }
                },
            }
            }
        },

        MuiTable: {
            styleOverrides: {
                root: {
                    "&.datatable": {
                        backgroundColor: '#fff',
                        "& > th ": {
                            padding: "20px 0",
                            color: 'black',
                            fontWeight: 'bold',
                        },
                        "& > tr ": {
                            color: 'black',
                            fontWeight: 'bold',
                            "&:hover": {
                                backgroundColor: "#fff !important"
                            }
                        },
                        "& > td ": {
                            fontWeight: 'bold',
                            padding: "20px 0"
                        },
                    },
                },
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    color: text,
                    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    minWidth: "0px",
                    overflowWrap: "break-word",
                    backgroundColor: "rgb(255, 255, 255)",
                    backgroundClip: "border-box",
                    border: "0px solid rgba(0, 0, 0, 0.125)",
                    borderRadius: "0.75rem",
                    boxShadow: "rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem",
                    overflow: "visible",
                    "& .MuiTableCell-root": {
                        padding: "15px !important",
                    },
                    "& .MuiTableCell-head": {
                        "&.MuiTableCell-paddingCheckbox": {
                            textAlign: "center",
                        },
                        "& span": {
                            fontWeight: "bold",
                            color: "rgb(123, 128, 154)",
                            fontSize: "12px",
                            textTransform: "uppercase",
                        }
                    },
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }
            }
        },

        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fdfdfd !important",
                    boxShadow: "none",
                },
            }
        },

        MuiToolbar: {
            styleOverrides: {
                root: {
                    "&.topbar-container": {
                        background:'red',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
                        color: text,
                        padding: "15px 20px",
                        margin: "20px",
                        borderRadius: "10px",

                        "& span": {
                            color: text
                        },
                        "& .topbar-left": {
                            display: "flex",
                            alignItems: "center ",
                            "& .container": {
                                marginRight: "30px",
                            },
                        },
                        "& .topbar-right": {
                            display: "flex",
                        },
                        "& .MuiTypography-h6": {
                            fontWeight: "bold",
                        },
                        "&.MuiBreadcrumbs": {
                            root: {
                                "& .MuiBreadcrumbs-li": {
                                    fontSize: "10px"
                                }
                            }
                        },
                    },

                    "&.custom-pagination": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        "& button": {
                            border: `1px solid ${lightBlue}`,
                            borderRadius: "20px",
                            minWidth: "unset !important",
                            width: "40px",
                            height: "40px",
                            margin: "0 10px",
                            "&.current-page": {
                                backgroundColor: lightBlue,
                                color: white,
                            }
                        }
                    },
                    "&.group-actions": {
                        display: "flex !important",
                        alignItems: "center !important",
                        justifyContent: "center !important"
                    },
                },
            }
        },

        RaLayout: {
            styleOverrides: {
                root: {
                    "& .RaLayout-appFrame":{
                    margin: "137px 20px 20px 20px !important",
                    "& #main-content": {
                        paddingLeft: "20px !important",
                        paddingRight: "0px !important"
                    }
                }
            }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    "& .MuiTypography-h6": {
                        color: 'black'
                    }
                }
            }
        },

        MuiButtonBase: {
            styleOverrides: {
                root: {
                    "&.actions-menu-button": {
                        textTransform: 'unset',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        color: '#000',
                        padding: '15px',
                    },
                    "&.action-buttons": {
                        position: "relative",
                        boxSizing: "border-box",
                        outline: "0px",
                        border: "0px",
                        cursor: "pointer",
                        verticalAlign: "middle",
                        appearance: "none",
                        textDecoration: "none",
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        minWidth: "64px",
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "0.75rem",
                        fontWeight: "700",
                        borderRadius: "0.5rem",
                        lineHeight: "1.4",
                        textAlign: "center",
                        //textTransform: "uppercase",
                        userSelect: "none",
                        transition: "all 150ms ease-in 0s",
                        backgroundImage: "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
                        backgroundPositionY: "initial",
                        backgroundRepeat: "initial",
                        backgroundAttachment: "initial",
                        backgroundOrigin: "initial",
                        backgroundClip: "initial",
                        backgroundColor: "initial",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "rgb(26 115 232 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(26 115 232 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(26 115 232 / 15%) 0rem 0.0625rem 0.3125rem 0rem",
                        backgroundSize: "150% !important",
                        backgroundPositionX: "25% !important",
                        padding: "10px",
                        margin: "20px 0",
                    },
                    //backgroundColor: '#ff2134' //test
                }
            }
        },
        MuiInputBase:{
            styleOverrides: {
                root: {
                    backgroundColor: "transparent !important",
                }
            }
        },

        RaBooleanField:{
            styleOverrides: {
                root: {
                    "& span": {
                        background: "transparent",
                        borderRadius: "20px",
                        border: "1px solid",
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }
                }
            }
        },

        MuiChip:{
            styleOverrides: {
                root: {
                    "&.MuiChip-deletable": {
                        backgroundColor: "transparent !important",
                        border: `1px solid ${lightBlue}`,
                        "& svg": {
                            color: lightBlue
                        }
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    "& .expand-table": {
                        margin: 'auto'
                    },
                }
            }
        },
    },
    palette: {
        primary: {
            main: text,
        },
        secondary: {
            main: "#777777",
        },
        navbar: {
            main: "rgba(255, 255, 255, 0.8)"
        }
    }
};
