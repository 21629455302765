import {useRecordContext, useTranslate} from "ra-core";
import {MenuItem} from "@mui/material";
import {useNotify} from "react-admin";

export const CopyTransactionLinkButton = (props) => {
    const {resource} = props;
    const record = useRecordContext();
    const notify = useNotify();
    const baseUrl = process.env.REACT_APP_BASE_URL_TRANSACTIONS_LINK ??  '';

    const translate = useTranslate();

    const copyToClipboard = async (text) => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    const handleCopy = () => {
        const link = baseUrl + record.transaction_id + "/1";
        copyToClipboard(link).then(() => {
            notify(`resources.${resource}.notifications.copy.success`, {type:'success'});
        })

    }

    return (
        <MenuItem onClick={handleCopy} disabled={props.disabled} className={props?.className}> {translate(`resources.${resource}.actions.copy_link`)}</MenuItem>
    )
}
