/*import moment from 'moment';
import 'moment/locale/it';*/
import polyglotI18nProvider from 'ra-i18n-polyglot';
import italian from "../../lang/it";




export const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'it') {
        /*moment.locale('it');*/
        return italian;
    }
    if (locale === 'en') {
        /*moment.locale('en');*/
        return import('../../lang/en.js').then(messages => messages.english);
    }
}, process.env.REACT_APP_LOCALE || 'it');
