import * as React from "react";
import {TextInput} from "react-admin";
import { NumericFormat } from 'react-number-format';
import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';


export const AmountInputText = ({register, ...props}) => {
    const {value, name, label, onChange} = props;

    const { setValue } = useFormContext();

    // const handleValueInCents = (v) => {
    //     setValueInCents(validateValue(v) * 100);
    //     setValue(formatValue(v));
    // }

    return (
                <NumericFormat
                    thousandSeparator={"."}
                    decimalSeparator={','}
                    prefix={'€'}
                    value={value}
                    customInput={TextField}
                    onValueChange={(values, sourceInfo) => {
                        onChange(sourceInfo, values['value'] * 100);
                        setValue('amount', values.value * 100)
                    }}
                    decimalScale={2}
                    valueIsNumericString
                    fullWidth
                    label={label}
                    {...register(name)}
                />
    )
}
