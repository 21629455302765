import jwt_decode from 'jwt-decode';
import {keycloak} from "../../../auth/keycloak/keycloak";

export const useKeycloakAuthProvider = (clientID) => {

  return ({
    login: () => keycloak.login(),
    checkError: () => {
      // todo implement check error logic
      Promise.resolve()
    },
    checkAuth: () => {
      return keycloak.authenticated && keycloak.token ? Promise.resolve() : Promise.reject("Failed to obtain access token.");
    },
    logout: () => keycloak.logout(),
    getIdentity: () => {
      if (keycloak.token) {
        const decoded  = jwt_decode(keycloak.token);
        const id = decoded.sub
        const fullName = decoded.name ?? decoded.preferred_username
        return Promise.resolve({id, fullName});
      }
      return Promise.reject("Failed to get identity");
    },
    getPermissions:() => {
      if (keycloak.token) {
        const decoded  = jwt_decode(keycloak.token);
        return decoded.resource_access ? Promise.resolve(decoded.resource_access) : Promise.resolve([]);
      }
      return Promise.reject("Failed to get permissions");
    },
  });
};