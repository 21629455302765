import * as React from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import {useTranslate} from "ra-core";
import {usePermissions, useRecordContext, useRefresh} from "react-admin";
import {MenuItem} from "@mui/material";
import ShowDialog from "../../Modals/ShowView";
import DeleteDialog from "../../Modals/DeleteDialog";
import EditDialog from "../../Modals/EditDialog";
import {hasRole} from "../../../../auth/keycloak/utilities/provider";

export const ActionsField = (props) => {
    const {canDelete, canEdit, additionalActions, restrictions = [], ...rest} = props;
    const record = useRecordContext();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [actionSelected, setActionSelected] = React.useState(null);
    const translate = useTranslate();
    const refresh = useRefresh();

    const handleChange = () => {
        refresh();
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const showDialog = (dialog) => {
        setActionSelected(dialog)
        handleClose()
    }

    const closeDialog = () => {
        setActionSelected(null)
    }

    return (
        <>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {!restrictions.includes('show') && <MenuItem key={record.id+'_show'} className={'actions-menu-button'} onClick={() => showDialog('show')}>{translate('ra.action.show')}</MenuItem>}
                {!restrictions.includes('edit') && <MenuItem key={record.id+'_edit'} className={'actions-menu-button'} onClick={() => showDialog('edit')}>{translate('ra.action.edit')}</MenuItem>}
                {!restrictions.includes('delete') && <MenuItem key={record.id+'_delete'} className={'actions-menu-button'} onClick={() => showDialog('delete')} disabled={record.rank?.length > 0}>{translate('ra.action.delete')}</MenuItem>}
                {additionalActions && additionalActions.map((action, index) => {
                    return React.cloneElement(action, {key: `action_${index}`})
                })}

            </Menu>
            {!restrictions.includes('show') && <ShowDialog open={actionSelected === 'show'} closeDialog={closeDialog} shouldBackdropClickClose {...rest} />}
            {!restrictions.includes('edit') && <EditDialog open={actionSelected === 'edit'} closeDialog={closeDialog} shouldBackdropClickClose={false} onChange={handleChange} {...rest} />}
            {!restrictions.includes('delete') && <DeleteDialog open={actionSelected === 'delete'} closeDialog={closeDialog}   {...rest} />}
        </>
    );



}
