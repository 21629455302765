import './App.css';
import PaymentIcon from '@mui/icons-material/Payment';
import {ReactKeycloakProvider} from "@react-keycloak/web";
import {keycloak} from "./auth/keycloak/keycloak";
import {keycloakProviderConfig} from "./auth/keycloak/config";
import {hasRole, onEvent, onToken, onTokenExpired} from "./auth/keycloak/utilities/provider";
import {useKeycloakAuthProvider} from "./providers/auth/KeycloakAuthProvider";
import {Admin, Resource, ShowGuesser, EditGuesser, ListGuesser} from "react-admin";
import {dataProvider} from "./providers/DataProvider";
import {UserList} from "./components/resources/User/UserList";
import * as React from "react";
import {theme} from "./assets/themes";
import {AppLayout} from "./assets/themes/Layout";
import {i18nProvider} from "./providers/Translation";
import {QueryClient} from "react-query";
import SanctumAuthProvider from "./providers/auth/SanctumAuthProvider/SanctumAuthProvider";
import {PaymentList} from "./components/resources/Payment/PaymentList";


function App() {
  const authProvider = process.env.REACT_APP_AUTH_PROVIDER;
  const keycloakAuthProvider = useKeycloakAuthProvider(process.env.REACT_APP_KEYCLOAK_CLIENT_ID);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 10000,
        /**
         * If `false`, failed queries will not retry by default.
         * If `true`, failed queries will retry infinitely., failureCount: num
         * If set to an integer number, e.g. 3, failed queries will retry until the failed query count meets that number.
         * If set to a function `(failureCount, error) => boolean` failed queries will retry until the function returns false.
         */
        retry: false,
        /**
         * If set to `true`, the query will refetch on window focus if the data is stale.
         * If set to `false`, the query will not refetch on window focus.
         * If set to `'always'`, the query will always refetch on window focus.
         * If set to a function, the function will be executed with the latest data and query to compute the value.
         * Defaults to `true`.
         */
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
      },
    },
  });


  // const fetchResources = (permissions) => {
  //   const canViewResources = hasRole("resources-view", permissions);
  //   let resources;
  //   if(canViewResources){
  //     resources = [
  //       <Resource key={'users'} name="users" list={UserList} icon={<VerifiedUserIcon/>} />,
  //       <Resource key={'groups'} name="groups" list={GroupList} icon={<GroupIcon/>} />,
  //       <Resource key={'specializations'} name="specializations" list={SpecializationList} icon={<GradeIcon/>} permissions={permissions}  />,
  //       <Resource key={'areas-of-interest'} name="areas-of-interest" list={AreasOfInterestList}  icon={<InterestsIcon/>} />
  //     ];
  //   }else{
  //     resources = [];
  //   }
  //   return resources;
  // }

  const resources = [
      <Resource icon={<PaymentIcon/>} key={'payments'} name="payments" list={PaymentList}/>,
  ];


  const initialState = {
    admin: { ui: { sidebarOpen: false, viewVersion: 0 } }
  }



  switch(authProvider) {
    case 'keycloak':
      return (
        <ReactKeycloakProvider
          LoadingComponent={<div>Loading react keycloak provider</div>}
          initOptions={keycloakProviderConfig}
          onTokenExpired={onTokenExpired}
          onTokens={onToken}
          onEvent={(event, error) => {console.log("event");console.log(event);console.log("error");console.log(error)}}
          authClient={keycloak}
        >
          <Admin
            authProvider={keycloakAuthProvider}
            dataProvider={dataProvider}
            loginPage={false}
            i18nProvider={i18nProvider}
            theme={theme}
            layout={AppLayout}
            initialState={initialState}
            queryClient={queryClient}
          >
            {resources}
          </Admin>
        </ReactKeycloakProvider>
      );
    default:
      return (
        <Admin
            authProvider={SanctumAuthProvider}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
            theme={theme}
            layout={AppLayout}
            initialState={initialState}
            queryClient={queryClient}
            requireAuth
        >
          {resources}
        </Admin>
      );
  }
}

export default App;
