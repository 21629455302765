import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Toolbar from '@mui/material/Toolbar';
import {FormControl, MenuItem, Select} from "@mui/material";
import {useListContext} from "react-admin";
import {useTranslate} from "ra-core";
import {useCallback} from "react";

export const CustomPagination = () => {
    const translate = useTranslate();
    const {
        page,
        perPage,
        setPerPage,
        hasPreviousPage,
        hasNextPage,
        setPage
    } = useListContext();


    if (!hasPreviousPage && !hasNextPage) return null;
    return (
        <Toolbar className={"custom-pagination"}>
            <FormControl variant="standard">
                <Select
                    id="page-size"
                    value={perPage ? perPage : 10}
                    label="Page size"
                    onChange={(e) => {
                        setPerPage(e.target.value)
                    }}
                >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                </Select>
            </FormControl>
            <>

            </>
            {hasPreviousPage &&
            <>
                <Button onClick={() => setPage(1)}>1</Button>
                <Button color="primary" key="prev" onClick={() => setPage(page - 1)}>
                    <NavigateBeforeIcon/>
                </Button>
            </>
            }

            {hasNextPage && <Button color="primary" key="next" onClick={() => setPage(page + 1)}>
                <NavigateNextIcon/>
            </Button>
            }
        </Toolbar>
    );
}
