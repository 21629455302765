import * as React from "react";
import {Box, Chip, Grid, Stack, TextField, Tooltip} from "@mui/material";
import {DateField} from "../DateField";
import {NumericFormat} from "react-number-format";


export const FormField = (props) => {
    const {type, value, title, name, renderOption = "name", ...rest} = props;


    const visualization = () => {

        if ((!value || !Object.keys(value).length) && type !== 'boolean') return <></>;
        const visualizations = {
            text: {
                formField:
                    <>
                        <label style={{fontWeight: 700}}>{title}</label>
                        <p>{value}</p>
                    </>
            },
            link: {
                formField:
                    <>
                        <label style={{fontWeight: 700}}>{title}</label>
                        <p><a href={value} rel="noreferrer" target="_blank" rel="noreferrer">{value}</a></p>
                    </>
            },
            list: {
                formField:
                    <>
                        <label style={{fontWeight: 700}}>{title}</label>
                        <Grid container rowSpacing={0.5} columnSpacing={0.5} marginTop={1} marginBottom={1}>
                            {Object.keys(value).map((k, index) => {
                                return (
                                    <Grid item key={`grid_${name}_${index}`}>
                                        <Chip
                                            key={`${name}_${index}`}
                                            label={value[k][renderOption]}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </>
            },
            date: {
                formField:
                    <>
                        <label style={{fontWeight: 700}}>{title}</label>
                        <p><DateField value={value}/></p>
                    </>
            },
            picture: {
                formField:
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <a href={value} rel="noreferrer" target="_blank">
                            <img src={value} width={rest.width ?? '100%'} height={rest.height ?? 150}/>
                        </a>
                    </Box>
            },
            currency: {
                formField:
                    <>
                        <label style={{fontWeight: 700}}>{title}</label>
                        <p><NumericFormat
                            thousandSeparator={"."}
                            decimalSeparator={','}
                            fixedDecimalScale
                            prefix={'€'}
                            decimalScale={2}
                            valueIsNumericString
                            displayType={'text'}
                            value={value / 100}
                        /></p>
                    </>
            }


        };

        return visualizations[type] ?? visualizations['text'];
    }

    const field = () => {
        return (
            <div>
                {visualization()['formField']}
            </div>
        );
    }


    return field();

}