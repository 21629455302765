import React from 'react';
import Dialog from '@mui/material/Dialog';
import {useTranslate} from "ra-core";

import {useRecordContext} from "react-admin";
import {PaymentShowFields} from "../../../resources/Payment/PaymentList/FormFields/ShowFields";


const ShowDialog = (props) => {
    const {resource, open, closeDialog, shouldBackdropClickClose} = props;
    const {record} = useRecordContext();
    const translate = useTranslate();

    const backdropClickCheck = (cause) => {
        return (cause !== 'backdropClick')
    }

    const onClose = (event, cause) => {
        if(shouldBackdropClickClose){
            closeDialog();
        }else{
            if(backdropClickCheck(cause))
                closeDialog();
        }
    }

    const getFields = (resource) => {
        const defaultProps = {
            resource: resource,
            onError: closeDialog,
            onClose: closeDialog,
        }
        const fields = {
            payments : <PaymentShowFields
                {...defaultProps}
            />,
        }
        return fields[resource] ?? <span>{translate('ra.page.error')}</span>;
    };



    return (
            <Dialog
                fullWidth
                open={open}
                onClose={(e, c) => onClose(e,c)}
                aria-label="show project"
            >
                <>
                    {getFields(resource) }
                </>
            </Dialog>

    );
}

export default ShowDialog;
